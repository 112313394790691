import React from "react";
import styled, { keyframes } from "styled-components";
import "./styles/App.css";
import myImage from './assets/images/me.png';
import { Link } from "react-router-dom";


const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const wave = keyframes`
  0% { transform: rotate(0deg); }
  10% { transform: rotate(14deg); }
  20% { transform: rotate(-8deg); }
  30% { transform: rotate(14deg); }
  40% { transform: rotate(-4deg); }
  50% { transform: rotate(10deg); }
  60% { transform: rotate(0deg); }
  100% { transform: rotate(0deg); }
`;

const Container = styled.div`
  position: relative;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 2rem auto;
  font-family: 'IBM Plex Serif', serif;
  
  @media (max-width: 1200px) {
    width: 70%;
  }
  
  @media (max-width: 1024px) {
    width: 80%;
  }
  
  @media (max-width: 768px) {
    width: 90%;
    margin: 1rem auto;
  }
`;

const HeroSection = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 2rem 0;
  
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    padding: 1rem 0;
  }
`;

const ProfileImage = styled.img`
  width: 400px;
  height: auto;
  border-radius: 20px;
  object-fit: cover;
  
  @media (max-width: 1200px) {
    width: 350px;
  }
  
  @media (max-width: 1024px) {
    width: 300px;
    margin: 2rem 0;
  }
  
  @media (max-width: 480px) {
    width: 250px;
  }
`;

const IntroParagraph = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  max-width: 600px;
  
  @media (max-width: 1024px) {
    text-align: center;
    padding: 0 1rem;
  }
`;

const Word = styled.div`
  margin: 0.3rem 0;
  padding: 0.5rem;
  font-size: 1.2em;
  
  @media (max-width: 1200px) {
    font-size: 1.1em;
  }
  
  @media (max-width: 768px) {
    font-size: 1em;
    padding: 0.3rem;
  }
  
  &.highlight {
    font-size: 1.4em;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 1rem;
    
    @media (max-width: 768px) {
      font-size: 1.2em;
    }
  }
  
  animation: ${fadeIn} 0.5s ease-out forwards;
`;

const Greeting = styled.h1`
  font-size: 2em;
  font-weight: 600;
  margin-bottom: 1.5rem;
  
  @media (max-width: 1200px) {
    font-size: 1.8em;
  }
  
  @media (max-width: 768px) {
    font-size: 1.6em;
    margin-bottom: 1rem;
  }
  
  span {
    display: inline-block;
    animation: ${wave} 2.5s infinite;
    transform-origin: 70% 70%;
  }
`;

const StyledLink = styled.a`
  color: black;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  display: inline;
  font-weight: 600;
  
  &:hover {
    color: grey;
    opacity: 0.8;
  }
`;

const BulletList = styled.ul`
  list-style: none;
  padding-left: 1rem;
  
  @media (max-width: 768px) {
    padding-left: 0.8rem;
  }
`;

const BulletPoint = styled.li`
  position: relative;
  padding: 1rem;
  
  &:before {
    content: "•";
    position: absolute;
    left: 0;
  }
`;

const EmailSection = styled.div`
  text-align: center;
  margin: 2rem 0;
  font-size: 1.1em;
  color: #4a4a4a;
`;

const StyledRouterLink = styled(Link)`
  color: black;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  display: inline;
  padding: 0 4px;
  font-weight: 600;
  
  &:hover {
    color: #4a4a4a;
    opacity: 0.8;
  }
`;

function Home() {
  return (
    <Container>
      <HeroSection>
        <ProfileImage src={myImage} alt="Diana" />
        <IntroParagraph>
          <Greeting>
            <span>👋</span> Hi, I'm Diana
          </Greeting>
          <Word>
            I grew up in Beijing and Ohio. I'm currently a senior at <strong>Harvard</strong> studying 
            <strong> Computer Science</strong> and <strong>Philosophy</strong>. I'm passionate about exploring 
            how technology can improve ~ human flourishing ~.
          </Word>
          
          <Word>
            At Harvard, I:
            <BulletList>
              <BulletPoint>
                am researching the meaning of work using philosophical frameworks 
                to guide AI system design principles at the {" "}
                <StyledLink href="https://iis.seas.harvard.edu/" target="_blank" rel="noopener noreferrer">
                  Interactive Intelligent Systems Group
                </StyledLink>
              </BulletPoint>
              <BulletPoint>
                am building a model to identify automatic human behavior in hopes of building tools for more proactive technology usage at {" "}
                <StyledLink href="https://projects.iq.harvard.edu/ccdlab/home" target="_blank" rel="noopener noreferrer">
                 Computation, Cognition, and Development Lab at Harvard
                </StyledLink>
              </BulletPoint>
              <BulletPoint>
                am the Co-President of the {" "}
                <StyledLink href="https://harvardcollegechinaforum.org/" target="_blank" rel="noopener noreferrer">
                  Harvard College China Forum
                </StyledLink>
                {" "}to foster dialogue on international dialogues
              </BulletPoint>
              <BulletPoint>
                helped lay the foundations of Harvard's first art-tech club {" "}
                <StyledLink href="https://confluxcollective.org/" target="_blank" rel="noopener noreferrer">
                  Conflux Collective
                </StyledLink>
              </BulletPoint>
            </BulletList>
            I've also coded at Microsoft, Datadog, and The Fabricant. Check out my <StyledRouterLink to="/Experiences">experiences</StyledRouterLink> here.
          </Word>
          <Word>
            Beyond tech, I'm passionate about visual storytelling — come 
            explore my paintings and photography! 🎨
          </Word>
        </IntroParagraph>
      </HeroSection>
      <EmailSection>
        dianayue@college.harvard.edu
      </EmailSection>
    </Container>
  );
}

export default Home;