import React from 'react';
import styled from 'styled-components';

const Word = styled.p`
  line-height: 1.6;
  margin: 1.5rem 0;
  font-family: 'IBM Plex Serif', serif;
`;

const StyledLink = styled.a`
  color: black;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  display: inline;
  padding: 0 4px;
  
  &:hover {
    color: #4a4a4a;
    opacity: 0.8;
  }
`;

const Container = styled.div`
  width: 60%;
  margin: 2rem auto;
  
  @media (max-width: 1200px) {
    width: 70%;
  }
  
  @media (max-width: 1024px) {
    width: 80%;
  }
  
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const ExperienceCard = styled.div`
  border: 1px solid;
  border-radius: 2px;
  padding: 1rem;
  transition: transform 0.2s ease;
  cursor: pointer;
  margin: 10px 0px;
  
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

const ExperienceHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.3rem;
`;

const Title = styled.h2`
  font-size: 1rem;
  margin: 0;
  font-family: 'IBM Plex Serif', serif;
`;

const ExternalLink = styled.a`
  text-decoration: none;
  color: inherit;
  
  &:hover {
    text-decoration: none;
  }
`;

const SectionTitle = styled.h2`
  font-size: 1.25rem;
  margin: 2rem 0 1rem;
  font-family: 'IBM Plex Serif', serif;
`;

// Updated data structure with two separate arrays
const generalExperiences = [
  {
    id: 1,
    title: "Intelligent Interactive Systems Group at Harvard (Current)",
    description:
      "I'm interviewing radiologists and synthesizing insights with philosophical frameworks (e.g., Kant, Aristotle) into design guidelines for AI systems that enhance workplace agency."
  },
  {
    id: 2,
    title: "Harvard College China Forum (Current)",
    description:
      "I'm co-leading the largest student-led conference on U.S.-China relations, attracting more than 1k attendees and 100 speakers annually in April at Harvard."
  },
  {
    id: 3,
    title: "Computation, Cognition, and Development Lab at Harvard (Current)",
    description:
      "I'm training a model to classify automaticity in videos of human behavior. Research with potential in advancing more proactive learnign, etc."
  },
  {
    id: 4,
    title: "Conflux Collective",
    description:
      "I helped build Conflux, Harvard's first art-tech club'. I focused on creating and growing its projects ecosystems."
  },
  {
    id: 5,
    title: "Global Research and Consulting Group",
    description:
      "I served as VP to outreach and maintain relations with our clients."
  },
  {
    id: 6,
    title: "Emerging Technology Group",
    description:
      "I served co-lead a project researching the potential for collaboration for U.S-China for Schmidt Futures."
  },
  {
    id: 7,
    title: "CAMLab Engineer Intern",
    description:
      "I helped build a model that predicts and generates dunhuang dances based on historical cave paintings of this dance style."
  },
  {
    id: 8,
    title: "Better Education for Stronger Teeth",
    description:
      "I founded a nonprofit that provides oral health products and education to children around the globe. Currently an advisor."
  },
];

const TimelineContainer = styled.div`
  width: 50%;
  position: relative;
  padding-left: 90px;
  margin: 1rem 0;
  font-family: 'IBM Plex Serif', serif;
  
  &::before {
    content: '';
    position: absolute;
    left: 70px;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: #000;
  }
`;

const TimelineItem = styled.div`
  position: relative;
  margin-bottom: 1.5rem;
  padding-top: 3px;
  }
`;

const TimelineTitle = styled.h3`
  font-size: 1rem;
  margin: 0;
  font-weight: bold;
  color: #000;
  font-family: 'IBM Plex Serif', serif;
`;

const TimelineSubtitle = styled.div`
  font-size: 0.8rem;
  color: #000;
  margin: 0.3rem 0;
  font-family: 'IBM Plex Serif', serif;
`;

const TimelineDate = styled.div`
  font-size: 1rem;
  color: #000;
  position: absolute;
  left: -120px;
  top: 6px; // Aligned with the title
  width: 60px;
  text-align: right;
  font-family: 'IBM Plex Serif', serif;
`;

const Description = styled.div`
  font-size: 0.8rem;
  line-height: 1.4;
  color: #000;
  font-family: 'IBM Plex Serif', serif;
`;

const experiences = [
    {
      id: 1,
      title: "Software Engineer Intern",
      organization: "Microsoft, Projects (Office 365)",
      year: "2024",
      description: "I designed and implemented a personalized email marketing campaigns to enhance user trial engagement and conversion for over 10 million users globally."
    },
    {
      id: 2,
      title: "Software Engineer Intern",
      organization: "Datadog, Dashboards and Notebooks",
      year: "2023",
      description: "I designed and implemented an autosave feature for our notebooks product."
    },
    {
        id: 3,
        title: "Investment Fellow",
        organization: "NEXT Capital",
        year: "2022",
        description:"Helped research and interview industries and founders in B2B SaaS, consumer, biotech, etc."
    },
    {
        id: 4,
        title: "Software Engineer Intern",
        organization: "The Fabricant",
        year: "2022",
        description: "I developed a proof-of-concept enabling users to generate Midjourney-created fashion items and automatically convert them into NFTs."
      },
  ];

  const InternshipsTimeline = ({ experiences }) => (
    <TimelineContainer>
      {experiences.map((exp) => (
        <TimelineItem key={exp.id}>
          <TimelineDate>{exp.year}</TimelineDate>
          <TimelineTitle>{exp.title}</TimelineTitle>
          <TimelineSubtitle>{exp.organization}</TimelineSubtitle>
          <Description>{exp.description}</Description>
        </TimelineItem>
      ))}
    </TimelineContainer>
  );

// Helper component for rendering experience cards
const ExperienceSection = ({ experiences }) => (
  <>
    {experiences.map((exp) => (
      <ExternalLink href={exp.link} target="_blank" rel="noopener noreferrer" key={exp.id}>
        <ExperienceCard>
          <ExperienceHeader>
            <Title>{exp.title}</Title>
          </ExperienceHeader>
          <Description>
            {exp.description}
          </Description>
        </ExperienceCard>
      </ExternalLink>
    ))}
  </>
);

function Experiences() {
  return (
    <Container>
      <SectionTitle>Internships</SectionTitle>
      <InternshipsTimeline experiences={experiences} />

      <SectionTitle>Experiences</SectionTitle>
      <ExperienceSection experiences={generalExperiences} />

      <Word>
        I've also previously built algorithms predicting real estate prices 
        for the <StyledLink href="https://www.longfor.com/" target="_blank" rel="noopener noreferrer">
        Longfor Real Estate Group</StyledLink> and researched films and done miscellaneous work for the
        global animations team at the <StyledLink href="https://en.wikipedia.org/wiki/Huayi_Brothers/" target="_blank" rel="noopener noreferrer">
        Huayi Brothers </StyledLink> - I hand copy-pasted the Chinese subtitles for the Paws of Fury
        movie 😼.
      </Word>
    </Container>
  );
}

export default Experiences;
