import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const COUNTRY_DESCRIPTIONS = {
    Norway: "",
    Beijing: "Exploring the contrast between ancient architecture and modern progress in China's capital.",
    Yiwu: "Documenting daily life of children who live in China's largest trading hubs. Is Yiwu Department Store a city of itself?",
    NewYork: "Street photography capturing the energy and diversity of the city that never sleeps."
  };

const Container = styled.div`
  width: 80%;
  margin: 2rem auto;
`;

const PhotoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
`;

const PhotoCard = styled.div`
  position: relative;
  aspect-ratio: 4/3;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.02);
  }
`;

const Photo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  position: relative;
  max-width: 90%;
  max-height: 90vh;
`;

const ModalImage = styled.img`
  max-width: 100%;
  max-height: 85vh;
  object-fit: contain;
`;

const CloseButton = styled.button`
  position: absolute;
  top: -2rem;
  right: -2rem;
  background: none;
  border: none;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  
  &:hover {
    opacity: 0.8;
  }
`;

const CountryTitle = styled.h1`
  text-align: center;
  margin-bottom: 2rem;
  text-transform: capitalize;
  font-family: 'IBM Plex Serif', serif;
`;

const CountryDescription = styled.p`
  text-align: center;
  margin: 0 auto 3rem;
  max-width: 800px;
  font-family: 'IBM Plex Serif', serif;
  font-size: 1.1rem;
  line-height: 1.6;
`;

function CountryGallery() {
  const { countryId } = useParams();
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const loadImages = async () => {
      try {
        const formattedCountryId = decodeURIComponent(countryId).replace(/%20/g, '');
        
        const countryModule = await import(`../assets/Photography/${formattedCountryId}/index.js`);
        
        const photoArray = Object.entries(countryModule.default).map(([filename, src], index) => ({
          id: `${countryId}-${index + 1}`,
          src: src,
          location: decodeURIComponent(countryId),
          caption: filename.split('.')[0].replace(/-/g, ' ')
        }));
        
        setPhotos(photoArray);
      } catch (error) {
        console.error(`Error loading images for ${countryId}:`, error);
        setPhotos([]);
      }
    };

    loadImages();
  }, [countryId]);

  const handleCloseModal = () => {
    setSelectedPhoto(null);
  };

  const handleModalClick = (e) => {
    if (e.target === e.currentTarget) {
      handleCloseModal();
    }
  };

  return (
    <Container>
      <CountryTitle>{countryId.replace(/-/g, ' ')}</CountryTitle>
      <CountryDescription>
        {COUNTRY_DESCRIPTIONS[countryId.replace(/%20/g, '')] || 'A collection of moments captured in this beautiful location.'}
      </CountryDescription>
      <PhotoGrid>
        {photos.map((photo) => (
          <PhotoCard key={photo.id} onClick={() => setSelectedPhoto(photo)}>
            <Photo src={photo.src} alt={photo.caption} />
          </PhotoCard>
        ))}
      </PhotoGrid>
      
      {selectedPhoto && (
        <Modal onClick={handleModalClick}>
          <ModalContent>
            <CloseButton onClick={handleCloseModal}>&times;</CloseButton>
            <ModalImage src={selectedPhoto.src} alt={selectedPhoto.caption} />
          </ModalContent>
        </Modal>
      )}
    </Container>
  );
}

export default CountryGallery; 