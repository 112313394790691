import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "./assets/images/logo.png";

const Logo = styled.img`
  height: 95px;
  width: 130px;
  margin-bottom: -1.5rem;

  @media (max-width: 768px) {
    margin-bottom: 0;
    margin-left: 0;
  }
`;

const NavbarContainer = styled.div`
  padding: 1rem;
  position: relative;
  border-bottom: 1px solid black;
  z-index: 2;
  font-family: 'IBM Plex Serif', serif;
  width: 100%;
  box-sizing: border-box;
`;

const NavbarContent = styled.div`
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const NavItems = styled.nav`
  display: flex;
  align-items: flex-end;
  margin-bottom: 0.5rem;
  font-family: 'IBM Plex Serif', serif;
  
  .ul {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  @media (max-width: 768px) {
    margin-top: 1rem;
    width: 100%;
    
    .ul {
      flex-direction: column;
      align-items: flex-start;
      gap: 1.2rem;
    }
  }
`;

const NavLink = styled(Link)`
  color: black;
  text-decoration: none;
  margin: 0 1.5rem;
  font-family: 'IBM Plex Serif', serif;
  font-size: 1.1rem;
  letter-spacing: 0.5px;
  transition: color 0.3s ease;
  font-weight: 600;

  @media (max-width: 768px) {
    margin: 0;
  }

  &:hover {
    text-decoration: underline;
    color: #4a4a4a;
  }
`;

function Navbar() {
  return (
    <NavbarContainer>
      <NavbarContent>
        <div className="logo">
          <Link to="/">
            <Logo src={logo} alt="Logo" />
          </Link>
        </div>
        <NavItems>
          <div className="ul">
            <div>
              <NavLink to="/Experiences">Experiences</NavLink>
            </div>
            <div>
              <NavLink to="/Art">Art</NavLink>
            </div>
            <div>
              <NavLink to="/Photography">Photography</NavLink>
            </div>
          </div>
        </NavItems>
      </NavbarContent>
    </NavbarContainer>
  );
}

export default Navbar;
