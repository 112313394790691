import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import norwayImage from './assets/Photography/Norway/Norway2.JPG';
import newYorkImage from './assets/Photography/New York/DSCF0588.jpg';
import beijingImage from './assets/Photography/Beijing/Beijing2.jpg'
import YiwuImage from './assets/Photography/Yiwu/DSCF0337.JPG'

const Container = styled.div`
  width: 80%;
  margin: 2rem auto;
`;

const PhotoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
`;

const CountryCard = styled(Link)`
  position: relative;
  aspect-ratio: 4/3;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease;
  text-decoration: none;
  
  &:hover {
    transform: scale(1.02);
  }
`;

const CoverImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CountryTitle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 1rem;
  text-align: center;
`;

const ComingSoon = styled.div`
  text-align: center;
  margin: 2rem 0;
  font-family: 'IBM Plex Serif', serif;
  font-size: 1rem;
`;

const countries = [
  {
    id: 'New York',
    name: 'New York, USA',
    coverImage: newYorkImage
  },
  {
    id: 'Beijing',
    name: 'Beijing, China',
    coverImage: beijingImage
  },
  {
    id: 'Yiwu',
    name: 'Yiwu, China',
    coverImage: YiwuImage
  },
  {
    id: 'Norway',
    name: 'Norway',
    coverImage: norwayImage
  },
];

function Photography() {
  return (
    <Container>
      <ComingSoon>more photos coming soon...</ComingSoon>
      <PhotoGrid>
        {countries.map((country) => (
          <CountryCard key={country.id} to={`/photography/${country.id}`}>
            <CoverImage src={country.coverImage} alt={country.name} />
            <CountryTitle>{country.name}</CountryTitle>
          </CountryCard>
        ))}
      </PhotoGrid>
    </Container>
  );
}

export default Photography;